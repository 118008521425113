import styled from 'styled-components'
import { colors } from '../../../assets/colors'

const Copyright = styled.p`
  font-size: 0.625rem;
  font-weight: 400;
  color: ${colors.grey};
`

export default Copyright
