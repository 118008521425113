import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Header from '../../../../components/Header'
import { InlineButton } from '../../../../components/Button/InlineButton'
import RedButton from '../../../../components/Button/RedButton'
import InboxHeader from '../../../../components/blocks/InboxHeader'
import SwitchButton from '../../../../components/Button/SwitchButton'
import APIClient from '../../../../apiClient'
import { RouteProps } from 'react-router-dom'
import Modal from '../../../../components/Modal'
import { colors } from '../../../../assets/colors'
import OptionsHeader from '../../../../components/blocks/Options/OptionsHeader'
import Options from '../../../../components/blocks/Options'
import Icon from '../../../../components/Icon'
import ImageCrop from '../../../../components/ImageCrop'
import { logoutUser } from '../../../../auth/logout'
import styled from 'styled-components'
import buildNumber from '../../../../buildNumber'
import { shallow } from 'zustand/shallow'
import { useMessagesActions, useMessagesStore } from '../../../../store/messages'
import { useAutoPlayActions, useAutoPlayStore } from '../../../../store/autoPlay'
import { showAlertModal, useModalActions, useModalsStore } from '../../../../store/modals'
import { useAvatar, useUser, useUserActions, useUserStore } from '../../../../store/user'
import CloseAccountModal from '../../../desktop/pages/Options/Ui/Components/CloseAccountModal'
import { useShallow } from 'zustand/react/shallow'
import { useClosureStatusQuery } from '../../../desktop/pages/Options/Ui/Components/CloseAccountModal/hooks'

const StyledButton = styled.div`
  cursor: pointer;
  opacity: 0.9;

  &:active {
    opacity: 0.7;
  }
  &:hover {
    opacity: 1;
  }
`

const MobileOptionsPage: React.FC<RouteProps & { match: any }> = (props) => {
  const history = useHistory()
  const [confirmLogoutModal, setConfirmLogoutModal] = useState(false)
  const [debug, setDebug] = useState(false)
  const user = useUser()
  const avatar = useAvatar()
  const { fetchUser, fetchUserAvatar } = useUserActions()
  const { setMessageCounter } = useMessagesActions()
  const { setAutoPlayEnabled } = useAutoPlayActions()
  const { setCloseAccountModal } = useModalActions()
  const { data: closureStatus, isLoading: isLoadingStatus } = useClosureStatusQuery()
  const messageCounter = useMessagesStore((state) => state.messageCounter, shallow)
  const autoPlay = useAutoPlayStore((state) => state.enabled, shallow)
  const billingProvider = useUserStore((state) => state.user.billing_provider)
  const closeAccountVisible = useModalsStore(useShallow((state) => state.closeAccount.visible))

  const changeAutoPlay = () => {
    setAutoPlayEnabled(!autoPlay)
  }

  useEffect(() => {
    ;(async function () {
      try {
        const res = await APIClient.voiceMailCounts()
        setMessageCounter(res.data)
      } catch (e) {
        console.log(e)
      }
    })()
  }, [history.location.pathname])

  const logout = async () => {
    try {
      await logoutUser()
    } catch (e: any) {
      showAlertModal(e.toString())
      console.log('error on logout: ', e)
    }
  }

  const onConfirmLogout = async () => {
    setConfirmLogoutModal(false)
    await logout()
  }

  const onImageUpload = async (imageFile: any) => {
    try {
      if (!imageFile) return
      const formData = new FormData()
      formData.append('photo', imageFile)
      await APIClient.setUserPhoto(formData)
      await fetchUser()
      await fetchUserAvatar()
    } catch (e: any) {
      console.log(e)
      showAlertModal(e.message)
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: colors.silver, height: '100%' }}>
      <Header>
        <Header.SideButtons>
          <InlineButton onClick={() => history.push('/')}>Done</InlineButton>
        </Header.SideButtons>
        <Header.Title>
          <span>Options</span>
        </Header.Title>
        <Header.EndButtons />
      </Header>
      <OptionsHeader>My Account</OptionsHeader>
      {billingProvider && billingProvider === 'stripe' && (
        <Options onClick={() => history.push('/billing?tab=payment-options')} clickable>
          Billing
          <StyledButton></StyledButton>
        </Options>
      )}
      <Options style={{ justifyContent: 'flex-end' }}>
        <Options.Text>{`${user?.first_name} ${user?.last_name} `}</Options.Text>
      </Options>
      <Options>
        Profile Photo
        <ImageCrop saveCroppedImage={onImageUpload} element={<div />}>
          <InboxHeader.Avatar src={avatar} width="25px" fontSize="8px" />
        </ImageCrop>
      </Options>
      <Options>
        Kate Number
        <Options.Text>{user?.access_numbers ? user?.access_numbers[0] : '000'}</Options.Text>
      </Options>
      <Options>
        Voice Messages
        <Options.Text>
          {`(${((messageCounter.total * 100) / messageCounter.limit).toFixed()}% full) ${messageCounter.total}/${
            messageCounter.limit
          }`}
        </Options.Text>
      </Options>
      <RedButton onClick={() => setConfirmLogoutModal(true)}>Log out from CommuniKate</RedButton>
      <OptionsHeader />
      {/*<Options onClick={changeNotifications}>*/}
      {/*  Receive Notifications*/}
      {/*  <SwitchButton isActive={notification} />*/}
      {/*</Options>*/}
      <Options onClick={changeAutoPlay} clickable>
        Auto Play New
        <SwitchButton isActive={autoPlay} />
      </Options>
      <OptionsHeader style={{ height: 50 }}>Voice Settings</OptionsHeader>
      <Options onClick={() => history.push('/callForwarding')} clickable>
        Call Forwarding
        <StyledButton>
          <Icon fill={colors.mediumGrey} name={'chevronRight'} style={{ width: '15px', height: '15px' }} />
        </StyledButton>
      </Options>
      <Options onClick={() => history.push('/greeting')} clickable>
        Voice Mail Greeting
        <StyledButton>
          <Icon fill={colors.mediumGrey} name={'chevronRight'} style={{ width: '15px', height: '15px' }} />
        </StyledButton>
      </Options>
      <Options onClick={() => history.push('/nameRecording')} clickable>
        Name Recording
        <StyledButton>
          <Icon fill={colors.mediumGrey} name={'chevronRight'} style={{ width: '15px', height: '15px' }} />
        </StyledButton>
      </Options>
      <OptionsHeader />
      <Options onClick={() => history.push('/feedback')} clickable>
        Send Feedback
        <StyledButton>
          <Icon fill={colors.mediumGrey} name={'chevronRight'} style={{ width: '15px', height: '15px' }} />
        </StyledButton>
      </Options>
      <Options onClick={() => setDebug(!debug)} clickable>
        Send debug info
        <SwitchButton isActive={debug} />
      </Options>
      <OptionsHeader />
      <Options
        onClick={() => setCloseAccountModal(true)}
        clickable
        style={closureStatus?.closurePending ? { color: 'red', fontWeight: 'bold' } : {}}
      >
        {closureStatus?.closurePending ? 'Account Closure Pending' : 'Request Account Cancellation'}
      </Options>
      <OptionsHeader />
      <Options
        onClick={() => {
          //@ts-ignore
          SnapEngage.openProactiveChat(true, true)
        }}
        clickable
      >
        Contact support
        <StyledButton>
          <Icon fill={colors.mediumGrey} name={'chevronRight'} style={{ width: '15px', height: '15px' }} />
        </StyledButton>
      </Options>
      <Options>
        Version
        <Options.Text>v{buildNumber}</Options.Text>
      </Options>
      <Options.Caption>
        Copyright 1997-{new Date().getFullYear()} Parus Holdings Inc. Parus communication products and services,
        excluding Parus Reminders, are protected by one or more U.S and international Patent No&apos;s 9,912,628;
        9,769,314; 6,775,264; 6,807,257; 7,327,723; 6,721,705; 7,076,431; 7,386,455; 7,881,941; 8,185,402; 7,516,190;
        9,377,992; 9,451,084; 9,571,445; and Canadian Patent 2,321,697; and European Patent 106,671,481. Other U.S. and
        international patents are pending.
      </Options.Caption>
      {closeAccountVisible && <CloseAccountModal />}
      <Modal show={confirmLogoutModal}>
        <Modal.TextContainer>
          <Modal.HeroText>CommuniKate</Modal.HeroText>
          <Modal.DescriptionText>Do you want to logout?</Modal.DescriptionText>
        </Modal.TextContainer>
        <Modal.ButtonsContainer>
          <Modal.Button onClick={() => setConfirmLogoutModal(false)} bold color={colors.contrastBlue}>
            No
          </Modal.Button>
          <Modal.Button onClick={onConfirmLogout} color={colors.contrastBlue}>
            Yes
          </Modal.Button>
        </Modal.ButtonsContainer>
      </Modal>
    </div>
  )
}

export default MobileOptionsPage
